import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Flex, CheckboxStateless, IconButton } from '../../../components';
import { InfoAlt as InfoIcon } from '@urbaninfrastructure/react-icons';
import { BlockContent } from '../../../components/BlockContent';

export interface CookiesTypeBlockProps {
  content?: CORE_JSON;
  nameMessageId: string;
  nameDefaultMessage: string;
  checkboxDisabled?: boolean;
  checked?: boolean;
  isOpen?: boolean;
  onOpen?: () => void;
  onChangeChecked?: () => void;
}

export const CookiesTypeBlock: FC<CookiesTypeBlockProps> = props => {
  const {
    content,
    nameMessageId,
    nameDefaultMessage,
    checkboxDisabled,
    checked,
    isOpen,
    onOpen,
    onChangeChecked
  } = props;
  if (!content) return null;

  return (
    <Flex flexDirection="column">
      <Flex mb={2} alignItems="center">
        <CheckboxStateless
          checked={checked}
          disabled={checkboxDisabled}
          onChange={onChangeChecked}
          label={
            <FormattedMessage
              id={nameMessageId}
              defaultMessage={nameDefaultMessage}
            />
          }
        />
        {Boolean(content?.length) && (
          <IconButton
            shape="cool"
            variant="inverted"
            Icon={InfoIcon}
            onClick={onOpen}
          />
        )}
      </Flex>
      {isOpen && <BlockContent blocks={content} />}
    </Flex>
  );
};
