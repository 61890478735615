import React, { FC, useContext, useState } from 'react';

export interface UserAcceptedCookies {
  performance?: boolean;
  functional?: boolean;
  targeting?: boolean;
}

export interface UserAcceptCookiesContextValue extends UserAcceptedCookies {
  setUserAcceptedCookies: (data: UserAcceptedCookies) => void;
}

export const UserAcceptedCookiesContext = React.createContext<
  UserAcceptCookiesContextValue
>({
  setUserAcceptedCookies: () => void 0
});

export interface UserAcceptedCookiesContextProviderProps {
  defaultPerformance?: boolean;
  defaultFunctional?: boolean;
  defaultTargeting?: boolean;
}

export const UserAcceptedCookiesContextProvider: FC<UserAcceptedCookiesContextProviderProps> = props => {
  const {
    children,
    defaultPerformance,
    defaultFunctional,
    defaultTargeting
  } = props;

  const [userAcceptedCookies, setUserAcceptedCookies] = useState<
    UserAcceptedCookies
  >({
    performance: defaultPerformance,
    functional: defaultFunctional,
    targeting: defaultTargeting
  });

  return (
    <UserAcceptedCookiesContext.Provider
      value={{
        performance: userAcceptedCookies.performance,
        functional: userAcceptedCookies.functional,
        targeting: userAcceptedCookies.targeting,
        setUserAcceptedCookies
      }}
    >
      {children}
    </UserAcceptedCookiesContext.Provider>
  );
};

export const useUserAcceptedCookies = (): UserAcceptCookiesContextValue =>
  useContext(UserAcceptedCookiesContext);
