import NProgress from 'nprogress';
import Router from 'next/router';
// import { createGlobalStyle, css } from 'styled-components';

let timeout;

const start = () => {
  timeout = setTimeout(NProgress.start, 100);
};

const done = () => {
  clearTimeout(timeout);
  NProgress.done();
};

Router.events.on('routeChangeStart', start);
Router.events.on('routeChangeComplete', done);
Router.events.on('routeChangeError', done);

// FIXME: when https://github.com/urbaninfrastructure/react-ui-kit/issues/24 is resolved, add NProgressStyles below
// export const NProgressStyles = createGlobalStyle`
// ${({ theme }) => {
//   return css`
//     /* Make clicks pass-through */
//     #nprogress {
//       pointer-events: none;
//     }

//     #nprogress .bar {
//       background: ${theme.colors.primary};

//       position: fixed;
//       z-index: 1031;
//       top: 0;
//       left: 0;

//       width: 100%;
//       height: 2px;
//     }

//     /* Fancy blur effect */
//     #nprogress .peg {
//       display: block;
//       position: absolute;
//       right: 0px;
//       width: 100px;
//       height: 100%;
//       box-shadow: 0 0 10px ${theme.colors.primary},
//         0 0 5px ${theme.colors.primary};
//       opacity: 1;

//       transform: rotate(3deg) translate(0px, -4px);
//     }
//   `;
// }}
// `;

export const NProgressStyles = () => null;
