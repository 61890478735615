import { BaseStyles } from '@urbaninfrastructure/react-ui-kit';
import App, { AppProps } from 'next/app';
import Head from 'next/head';
import { RawIntlProvider, createIntl, createIntlCache } from 'react-intl';
import { EnvironmentBar } from '../components/EnvironmentBar';
import { NProgressStyles } from '../components/NProgress';
import { ToastProvider, Toasts } from '../components/Toasts';
import { staticDataQuery, staticDataQuery_system } from '../core-types';
import { urbansharingAssetsPath } from '../lib/constants';
import { STATIC_DATA_QUERY } from '../lib/core/staticDataQuery';
import { SystemConfigProvider } from '../lib/system-config-context';
import { getTheme } from '../lib/theme';
import {
  CustomAppContext,
  CustomNextPageContext,
  SystemConfig
} from '../lib/types';
import { getFaviconKey, getFormats, isAppContent } from '../lib/util';

// init sentry
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { ThemeKey } from '@urbaninfrastructure/design-tokens';
import cookies from '../lib/cookies';
import '../lib/sentry';
import { UserAcceptedCookiesContextProvider } from '../lib/user-accepted-cookies-context';
import { CookiesType } from '../modules/Layout/CookiesNotification';
import routeConfig from '../lib/routes/config';
import { containsPageSlug } from '../utils/pageSlugCheck';

interface MyWindow extends Window {
  __NEXT_DATA__: any;
  fbq?: (event: string, name: string) => void;
}

interface PublicWebAppProps {
  baseUrl: string;
  systemConfig: SystemConfig;
  locale: string;
  messages: Record<string, string>;
  staticDataSystem: staticDataQuery_system;
  defaultPerformance?: boolean;
  defaultFunctional?: boolean;
  defaultTargeting?: boolean;
}

declare var window: MyWindow;

const Favicons = ({ faviconKey }: { faviconKey: string | undefined }) => {
  if (!faviconKey) {
    return null;
  }

  const path = `${urbansharingAssetsPath}/v1/favicons/public-web/${faviconKey}`;

  return (
    <Head>
      <link
        key="favicon-16"
        rel="icon"
        href={`${path}/favicon-16x16.png`}
        type="image/x-icon"
        sizes="16x16"
      />
      <link
        key="favicon-32"
        rel="icon"
        href={`${path}/favicon-32x32.png`}
        type="image/x-icon"
        sizes="32x32"
      />
      <link
        key={`apple-touch-icon`}
        rel="apple-touch-icon"
        href={`${path}/apple-touch-icon-180x180.png`}
        type="image/png"
        sizes="180x180"
      />
    </Head>
  );
};

function getBaseUrl(req: CustomNextPageContext['req']) {
  if (req) {
    const scheme =
      (req.headers &&
        req.headers['x-forwarded-proto'] &&
        req.headers['x-forwarded-proto'].toString()) ||
      'http';
    return `${scheme}://${req.headers.host}`;
  }
  return window.__NEXT_DATA__.props.baseUrl;
}

// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache();

function PublicWebApp(
  props: AppProps &
    PublicWebAppProps & {
      apolloClient: ApolloClient<NormalizedCacheObject>;
      err?: Error;
    }
) {
  const {
    Component,
    pageProps,
    router,
    systemConfig,
    staticDataSystem,
    baseUrl,
    locale,
    messages,
    defaultPerformance,
    defaultTargeting,
    defaultFunctional
  } = props;

  const colorKey = (staticDataSystem.colourKey as ThemeKey) || undefined;

  // Workaround for https://github.com/zeit/next.js/issues/8592
  const { err } = props;
  const modifiedPageProps = { ...pageProps, err };

  const theme = getTheme({
    colorKey,
    isApp: isAppContent(router.query)
  });

  //MUI theme overrides
  const muiTheme = createTheme({
    overrides: {
      MuiPaper: {
        root: {
          color: '#fff',
          '& .MuiButtonBase-root a': {
            color: theme.colors.primary
          },
          '& .MuiList-root': {
            padding: 0
          }
        }
      },
      MuiListItem: {
        root: {
          '&$selected': {
            backgroundColor: theme.colors.primary,
            color: '#fff',
            '&$selected a': {
              color: '#fff'
            },
            '&:hover': {
              backgroundColor: theme.colors.primary
            }
          }
        },
        button: {
          '&:hover': {
            backgroundColor: '#fff'
          }
        }
      },
      MuiButtonBase: {
        root: {
          '&:hover': {
            borderBottom: 'none'
          },
          '& .MuiListItem-root': {
            padding: '0'
          }
        }
      },
      MuiInputBase: {
        root: {
          fontFamily: '"Urban Grotesk", "Helvetica Neue", sans-serif',
          padding: 0,
          '&:before, &:after': {
            display: 'none'
          },
          '& .MuiSelect-root': {
            padding: '0 15px 0 0'
          }
        }
      },
      MuiMenuItem: {
        root: {
          fontFamily: '"Urban Grotesk", "Helvetica Neue", sans-serif'
        }
      },
      MuiSelect: {
        select: {
          '&:focus': {
            backgroundColor: 'transparent'
          },
          '&.MuiSelect-select': {
            paddingRight: '15px'
          }
        }
      },
      MuiSvgIcon: {
        root: {
          width: '1.5em'
        }
      }
    }
  });

  // react-intl setup
  const formats = getFormats(staticDataSystem.currency || undefined);
  const intl = createIntl(
    {
      locale,
      defaultLocale: staticDataSystem.defaultLanguageCode || locale,
      messages,
      formats,
      defaultFormats: formats,
      timeZone: staticDataSystem.timezone || undefined
    },
    cache
  );

  return (
    <>
      <RawIntlProvider key={locale} value={intl}>
        <SystemConfigProvider
          value={{
            systemConfig,
            baseUrl
          }}
        >
          <BaseStyles font={'urban-grotesk'} colorKey={colorKey} theme={theme}>
            <ThemeProvider theme={muiTheme}>
              <>
                <EnvironmentBar />
                <NProgressStyles />
                {colorKey && (
                  <Favicons
                    faviconKey={getFaviconKey(systemConfig, staticDataSystem)}
                  />
                )}
                <ToastProvider>
                  <UserAcceptedCookiesContextProvider
                    defaultFunctional={defaultFunctional}
                    defaultPerformance={defaultPerformance}
                    defaultTargeting={defaultTargeting}
                  >
                    <Component
                      url={router}
                      systemConfig={systemConfig}
                      {...modifiedPageProps}
                    />
                    <Toasts />
                  </UserAcceptedCookiesContextProvider>
                </ToastProvider>
              </>
            </ThemeProvider>
          </BaseStyles>
        </SystemConfigProvider>
      </RawIntlProvider>
    </>
  );
}

PublicWebApp.getInitialProps = async (
  appContext: CustomAppContext
): Promise<
  PublicWebAppProps & {
    pageProps: AppProps['pageProps'];
  }
> => {
  const { ctx } = appContext;
  const baseUrl = getBaseUrl(ctx.req);

  const { systemConfig } =
    ctx.req || (window.__NEXT_DATA__.props as { systemConfig: SystemConfig });

  // react-intl setup
  if (ctx.req) {
    const { intl } = await import('../lib/intl');
    intl(ctx.req, ctx.query, systemConfig.defaultLanguageCode);
  }

  const appProps = await App.getInitialProps(appContext);

  const { data } = await ctx.apolloClient.query<staticDataQuery>({
    query: STATIC_DATA_QUERY,
    fetchPolicy: 'cache-first'
  });

  if (!data || !data.system) {
    throw new Error('Could not load system');
  }

  // We hide the /buy page if registration is disabled
  // An extra layer of protection can be added in the page component, but I think this is enough
  if (data.system.registrationEnabled === false) {
    const isbuySubscriptionPage = containsPageSlug(
      ctx?.req?.url,
      Object.values(routeConfig['buy'].slug || {})
    );
    // If route /buy is accessed and registration is disabled, return 404
    if (isbuySubscriptionPage) {
      ctx?.res?.writeHead(404, {
        Location: '/'
      });
      ctx?.res?.end();
    }
  }

  const parsedCookies = cookies.parse(ctx.req);

  const { locale, messages } = ctx.req || window.__NEXT_DATA__.props;

  return {
    pageProps: appProps.pageProps,
    baseUrl,
    locale,
    messages,
    systemConfig,
    staticDataSystem: data.system,
    defaultPerformance: parsedCookies[CookiesType.Performance] && true,
    defaultFunctional: parsedCookies[CookiesType.Functional] && true,
    defaultTargeting: parsedCookies[CookiesType.Targeting] && true
  };
};

export default PublicWebApp;
