import { useState, useEffect } from 'react';
import getConfig from 'next/config';
import { Button } from '@urbaninfrastructure/react-ui-kit';
import Flex from './Flex';
import Box from './Box';

const {
  publicRuntimeConfig: { APP_ENV }
} = getConfig();

export function EnvironmentBar() {
  const [visible, setVisible] = useState(APP_ENV === 'staging');
  const [showHideButton, setShowHideButton] = useState(false);
  useEffect(() => {
    setShowHideButton(true);
  }, []);
  if (!visible) {
    return null;
  }
  return (
    <Flex
      fontSize="14px"
      color="black"
      bg={APP_ENV === 'staging' ? '#fe6c33' : '#3a9dff'}
      px={2}
      alignItems="center"
    >
      <Box flex={1}>⚠️{APP_ENV}</Box>
      {showHideButton && (
        <Button onClick={() => setVisible(false)} size="small" variant="reset">
          Hide
        </Button>
      )}
    </Flex>
  );
}
