export function containsPageSlug(
  str: string | undefined,
  slugs: string[]
): boolean {
  for (const value of slugs) {
    if (str?.includes(value)) {
      return true;
    }
  }
  return false;
}
