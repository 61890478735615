import { FC, createContext, useContext, useReducer, useEffect } from 'react';
import * as React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Box, Flex, Text, Button } from '..';
import styled from 'styled-components';
import { BoxProps } from '@urbaninfrastructure/react-ui-kit';

interface Toast {
  id: string;
  text: React.ReactNode;
  type?: 'info' | 'success' | 'warning' | 'error';
  ttl: number;
  primaryAction?: {
    text: React.ReactNode;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  };
}

interface ToastInput extends Omit<Toast, 'id' | 'ttl'> {
  id?: string;
  ttl?: number;
}

type Action =
  | { type: 'ADD_TOAST'; payload: Toast }
  | { type: 'REMOVE_TOAST'; payload: string };

const initialState = [];

function toastReducer(state: Toast[], action: Action) {
  switch (action.type) {
    case 'ADD_TOAST': {
      if (state.some(toast => toast.id === action.payload.id)) {
        return state;
      }
      return [...state, action.payload];
    }
    case 'REMOVE_TOAST': {
      return state.filter(toast => action.payload !== toast.id);
    }
    default:
      return state;
  }
}

export const ToastContext = createContext<[Toast[], React.Dispatch<Action>]>([
  initialState,
  () => initialState
]);

export const ToastProvider: FC<{}> = props => {
  const reducer = useReducer(toastReducer, initialState);
  return (
    <ToastContext.Provider value={reducer}>
      {props.children}
    </ToastContext.Provider>
  );
};

export function useToasts(): {
  toasts: Toast[];
  addToast: (toast: ToastInput) => void;
  removeToast: (id: string) => void;
} {
  const [toasts, dispatch] = useContext(ToastContext);

  /** Displays a toast until ttl (time to live) expires */
  function addToast({
    id = Math.random().toString(),
    ttl = 3000,
    ...toast
  }: ToastInput) {
    dispatch({
      type: 'ADD_TOAST',
      payload: { id, ttl, ...toast }
    });
  }

  /** Remove a toast by its id */
  function removeToast(id: string) {
    dispatch({
      type: 'REMOVE_TOAST',
      payload: id
    });
  }

  return { toasts, addToast, removeToast };
}

const Toast = ({
  toast,
  dispatch
}: {
  toast: Toast;
  dispatch: React.Dispatch<Action>;
}) => {
  const { id, ttl, primaryAction } = toast;
  useEffect(() => {
    if (ttl) {
      const timeoutId = setTimeout(() => {
        dispatch({ type: 'REMOVE_TOAST', payload: id });
      }, ttl);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [id, ttl]);

  return (
    <Flex
      p="xs"
      mb={2}
      borderRadius="md"
      maxWidth={{ _: 400, lg: 600 }}
      boxShadow="heavy"
      bg={toast.type ? `state.${toast.type}` : 'white'}
      color={toast.type ? '#222' : 'text'}
      alignItems="center"
      flexDirection={{ _: 'column', md: 'row' }}
      mx="auto"
    >
      <Text typoStyle="xxs">{toast.text}</Text>
      {primaryAction && (
        <Box mt={{ _: 2, md: 0 }} ml={{ _: 0, md: 2 }}>
          <Button
            onClick={primaryAction.onClick}
            variant="primary"
            size="small"
          >
            {primaryAction.text}
          </Button>
        </Box>
      )}
    </Flex>
  );
};

const ToastBox = styled(Box)<BoxProps>`
  ${({ theme }) => {
    return `
    ${theme.mediaQueries[0]} {
      transform: translateX(-50%);
    }
`;
  }}
`;

export const Toasts = () => {
  const [toasts, dispatch] = useContext(ToastContext);
  return (
    <ToastBox
      data-testid="Toasts"
      position="fixed"
      top={0}
      left={{ sm: '50%' }}
      width={{ _: '95%', sm: 'auto' }}
      zIndex={1000}
      mt="sm"
    >
      <AnimatePresence initial={true}>
        {toasts.map(toast => {
          return (
            <motion.div
              key={toast.id}
              layout="position"
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, transition: { duration: 0.2 } }}
            >
              <Toast dispatch={dispatch} toast={toast} />
            </motion.div>
          );
        })}
      </AnimatePresence>
    </ToastBox>
  );
};
