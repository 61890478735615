import { useEffect, FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { motion, AnimatePresence } from 'framer-motion';
import { Box, Button, Flex, Text } from '../../../components';
import { BlockContent } from '../../../components/BlockContent';
import cookies from '../../../lib/cookies';
import useBool from '../../../lib/hooks/useBool';
import { CookiesTypeBlock } from './CookiesTypeBlock';
import { UserAcceptedCookies } from '../../../lib/user-accepted-cookies-context';

const COOKIE_NAME = 'cookieconsent';
const COOKIE_ACCEPTED_VALUE = 'accepted';

export enum CookiesType {
  Strictly = 'strictly',
  Performance = 'performance',
  Functional = 'functional',
  Targeting = 'targeting'
}

export interface CookieNotificationProps {
  cookieNotification: CORE_JSON;
  strictlyNecessaryCookiesContent: CORE_JSON;
  performanceCookiesContent: CORE_JSON;
  functionalCookiesContent: CORE_JSON;
  targetingCookiesContent: CORE_JSON;
  onClose?: (data?: UserAcceptedCookies) => void;
}

export const CookiesNotification: FC<CookieNotificationProps> = props => {
  const {
    cookieNotification,
    strictlyNecessaryCookiesContent,
    performanceCookiesContent,
    functionalCookiesContent,
    targetingCookiesContent,
    onClose
  } = props;

  const [
    isCookieNotificationOpen,
    ,
    openCookieNotification,
    closeCookieNotification
  ] = useBool();
  const [
    isUserAgreedToPerformanceCookies,
    switchIsUserAgreedToPerformanceCookies
  ] = useBool();
  const [
    isUserAgreedToFunctionalCookies,
    switchIsUserAgreedToFunctionalCookies
  ] = useBool();
  const [
    isUserAgreedToTargetingCookies,
    switchIsUserAgreedToTargetingCookies
  ] = useBool();

  const [currentOpenCookiesType, setCurrentOpenCookiesType] = useState<
    CookiesType
  >();
  const openCookiesType = (cookiesType: CookiesType) => {
    setCurrentOpenCookiesType(prev => {
      if (prev === cookiesType) {
        return undefined;
      }
      return cookiesType;
    });
  };
  useEffect(() => {
    const parsedCookies = cookies.parse();

    if (parsedCookies[COOKIE_NAME] !== COOKIE_ACCEPTED_VALUE) {
      openCookieNotification();
    }
  }, []);

  const onlyStrictlyNecessaryCookiesEnabled =
    !performanceCookiesContent &&
    !functionalCookiesContent &&
    !targetingCookiesContent;

  const handleClose = (data?: UserAcceptedCookies) => {
    closeCookieNotification();

    document.cookie = cookies.serialize(COOKIE_NAME, COOKIE_ACCEPTED_VALUE, {
      maxAge: 31556926,
      path: '/'
    });
    if (data?.performance) {
      document.cookie = cookies.serialize(CookiesType.Performance, 'true', {
        maxAge: 31556926,
        path: '/'
      });
    }
    if (data?.functional) {
      document.cookie = cookies.serialize(CookiesType.Functional, 'true', {
        maxAge: 31556926,
        path: '/'
      });
    }
    if (data?.targeting) {
      document.cookie = cookies.serialize(CookiesType.Targeting, 'true', {
        maxAge: 31556926,
        path: '/'
      });
    }
    if (onClose) {
      onClose(data);
    }
  };

  return (
    <AnimatePresence initial={true}>
      {isCookieNotificationOpen && (
        <Box
          key="showCookieNotification"
          as={motion.div}
          data-testid="CookieNotification"
          initial={{ x: '-100%', opacity: 0 }}
          animate={{ x: 0, opacity: 1, transition: { delay: 1 } }}
          exit={{ x: '-100%', opacity: 0 }}
          role="alert"
          position="fixed"
          zIndex={10}
          overflowY="scroll"
          borderRadius="md"
          boxShadow="heavy"
          bg="white"
          left={2}
          bottom={2}
          fontSize={1}
          lineHeight={1.5}
          p="xs"
          maxHeight={{ _: '70vh', md: '500px', lg: '650px' }}
          maxWidth={{ _: '70vw', md: '500px', lg: '650px' }}
          css={`
            p {
              margin-bottom: 0;
            }
          `}
        >
          {isCookieNotificationOpen && (
            <>
              <Text as="h3">
                <FormattedMessage
                  defaultMessage="Cookie Settings"
                  id="cookie_settings"
                />
              </Text>
              <BlockContent blocks={cookieNotification} mb={3} />
              <CookiesTypeBlock
                content={strictlyNecessaryCookiesContent}
                nameMessageId="strictly_necessary_cookies"
                nameDefaultMessage="Strictly Necessary"
                isOpen={currentOpenCookiesType === CookiesType.Strictly}
                onOpen={() => openCookiesType(CookiesType.Strictly)}
                checkboxDisabled
                checked
              />
              <CookiesTypeBlock
                content={performanceCookiesContent}
                nameMessageId="performance_cookies"
                nameDefaultMessage="Performance Cookies"
                isOpen={currentOpenCookiesType === CookiesType.Performance}
                onOpen={() => openCookiesType(CookiesType.Performance)}
                checked={isUserAgreedToPerformanceCookies}
                onChangeChecked={() => switchIsUserAgreedToPerformanceCookies()}
              />
              <CookiesTypeBlock
                content={functionalCookiesContent}
                nameMessageId="functional_cookies"
                nameDefaultMessage="Functional Cookies"
                isOpen={currentOpenCookiesType === CookiesType.Functional}
                onOpen={() => openCookiesType(CookiesType.Functional)}
                checked={isUserAgreedToFunctionalCookies}
                onChangeChecked={() => switchIsUserAgreedToFunctionalCookies()}
              />
              <CookiesTypeBlock
                content={targetingCookiesContent}
                nameMessageId="targeting_cookies"
                nameDefaultMessage="Targeting Cookies"
                isOpen={currentOpenCookiesType === CookiesType.Targeting}
                onOpen={() => openCookiesType(CookiesType.Targeting)}
                checked={isUserAgreedToTargetingCookies}
                onChangeChecked={() => switchIsUserAgreedToTargetingCookies()}
              />
              <Flex justifyContent="space-between" mt={4}>
                <Flex>
                  <Button
                    variant="primary"
                    size="small"
                    shape="cool"
                    onClick={() =>
                      handleClose({
                        performance:
                          performanceCookiesContent &&
                          isUserAgreedToPerformanceCookies,
                        functional:
                          functionalCookiesContent &&
                          isUserAgreedToFunctionalCookies,
                        targeting:
                          targetingCookiesContent &&
                          isUserAgreedToTargetingCookies
                      })
                    }
                    mr={2}
                  >
                    <FormattedMessage id="confirm" defaultMessage="Confirm" />
                  </Button>
                  {!onlyStrictlyNecessaryCookiesEnabled && (
                    <Button
                      variant="primary"
                      size="small"
                      shape="cool"
                      onClick={() =>
                        handleClose({
                          performance: performanceCookiesContent && true,
                          functional: functionalCookiesContent && true,
                          targeting: targetingCookiesContent && true
                        })
                      }
                    >
                      <FormattedMessage
                        id="accept_all"
                        defaultMessage="Accept all"
                      />
                    </Button>
                  )}
                </Flex>
                {!onlyStrictlyNecessaryCookiesEnabled && (
                  <Button
                    variant="primary"
                    size="small"
                    shape="cool"
                    onClick={() => handleClose()}
                  >
                    <FormattedMessage id="cancel" defaultMessage="Cancel" />
                  </Button>
                )}
              </Flex>
            </>
          )}
        </Box>
      )}
    </AnimatePresence>
  );
};
